import * as types from './types'
import { APP_BUSINESS_PARTNER_ROLE_PNH } from '@/constants/auth'
export default {
  [types.USER]: state => {
    return state.user
  },
  [types.TOKEN]: state => {
    return state.token
  },
  [types.IS_HUNTER]: state => {
    return state?.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNH
  }
}
